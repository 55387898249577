@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* ------ General ------ */
* {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: #333;
}

hr {
  margin: 2em 0;
}

@keyframes shake {
  25% { transform: translateX(4px); }
  50% { transform: translateX(-4px); }
  75% { transform: translateX(4px); }
}

::-moz-selection {
  color: #fff;
  background: #181818;
}

::selection {
  color: #fff;
  background: #181818;
}

@keyframes scroll  {
  to {
       transform: translateX(-100%);
    }
  }

.scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 230px;
  margin: auto;
  overflow: hidden;
  z-index: 1;
}

#scroll-text {
  position: absolute;
  white-space: nowrap;
  animation: scroll 15s linear infinite;
  transform: translateX(100%);
  line-height: 1.4em;
  z-index: 999999999;
}


@keyframes slideInFromAbove {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade {
  0% { opacity: 0 }
  10%, 90% { opacity: 1 }
  100% { opacity: 0 }
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #DCD9D4;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%);
  background-blend-mode: soft-light,screen;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.4em;
  animation: 300ms ease-out 0s 1 slideInFromAbove;
}

a {
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
}

a:hover {
  border-bottom: #333 1px solid;
}

.white, .white a, .white > i {
  color: #fff !important;
}

.content > .white:hover, .white a:hover {
  border-bottom: #fff 1px solid !important;
}

.light-text {
  color: #fff !important;
}

.error {
  opacity: 1;
  animation: fade 5s linear;
  background-color: #d40000;
  box-shadow: 0px 15px 20px rgba(229, 46, 46, 0.4);
  color: #fff;
  padding: 10px;
  margin: 20px 0;
  border-radius: 4px;
}

.row {
  display: flex; /* equal height of the children */
  flex-wrap: wrap;
  width: 100%;
}

.col {
  align-content: flex-start;
  display: flex;
  flex-direction: column;
}

.col-record {
  width: 100%;
}

.row-record:after {
  content: "";
  display: table;
  clear: both;
}

.left {
  display: block;
  float: left;
  max-width: 45%;
}

.right {
  display: block;
  float: left;
  max-width: 51%;
  margin: 0 2%;
}

.button-container {
  display: block;
  clear: both;
}

.title-border {
  width: 800px;
  margin: -25px auto 15px auto;
  border: #c62233 solid 2px;
}

.text-bg {
  background-color: #00000080;
  width: fit-content;
  padding: 5px;
  margin-bottom: 5px;
  backdrop-filter: blur(10px);
  border-radius: 4px;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.small {
  font-size: 12px;
  letter-spacing: 1px;
}

.medium {
  font-size: 22px;
  font-weight: 400;
}

.large {
  font-size: 3vw;
  font-weight: 700;
}

.extra-large {
  font-size: 5vw;
  font-weight: 700;
}

.extreme {
  font-size: 9vw;
  font-weight: 700;
  letter-spacing: -5px;
  line-height: 0.8em;
  margin: 0.4em;
  text-transform: lowercase;
}

.center {
  text-align: center !important;
}

.center-div {
  display: block;
  margin: 0 auto
}

.loading {
  display: flex;
  justify-content: center;
  flex-direction: row;
	flex-wrap: wrap;
  width: 100%;
  font-size: 1.5em;
  letter-spacing: 0;
  transition: all 0.3s ease 0s;
}

.block {
  display: block;
  clear: both;
}

.fixedbutton {
  z-index: 999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(20px) !important;
}

.fixedbutton:hover {
  background-color: #c62233 !important;
  backdrop-filter: blur(20px) !important;
}

.bi .bi-boombox-fill svg {
  margin-bottom: 3em;
}

/* From uiverse.io */
button {
  margin: 1.2em 0;
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  min-width: fit-content;
 }

button:hover {
  background-color: #c62233;
  box-shadow: 0px 15px 20px rgba(229, 46, 46, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
 
button:active {
  transform: translateY(-1px);
}

.header-wrapper {
   width: 100%;
   margin: 0;
   padding: 0;
}

.removeBtnStyle {
  margin: 0.4em 0;
  padding: 0;
  background-color: transparent;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border-bottom: transparent solid 1px;
  transition: none;
}

.removeBtnStyle:hover {
  background-color: transparent;
  color: #333;
  border-bottom: #333 solid 1px;
  box-shadow: none;
  transform: none;
}

 /* ------ Menu ------ */

 .link-active-white {
   border-bottom: #fff 1px solid;
 }

.logo {
  margin-right: 50px;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  padding: 30px 10px;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: 1px solid #00000020;
}

.navbar a {
  text-decoration: none;
  padding-bottom: 5px;
  color: inherit;
}

.navbar a:hover, .navbar .link-active {
  border-bottom: #333 solid 1px;
}

.list {
  list-style: none;
  display: flex;
  gap: 25px;
}

#toggler,
.navbar label {
  display: none;
}

.navbar h1 a:hover {
  color: #fff !important;
  border-bottom: 0 !important;
  background-color: #111 !important;
  transition: all 0.3s ease 0s;
}

.navbar h1 a {
  margin-left: 0 !important;
  padding: 0 !important;
  font-weight: 800;
  font-size: 20px;
  position: relative;
  top: 10px;
  line-height: 1em;
}

.navbar .links {
  margin-left: auto;
}

.bg {
  background-image: url("../public/img/frontpage-concert.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -104px;
  padding: 130px 0 30px 0;
  height: 75vh;
}

.content {
  max-width: 1200px;
  margin: 30px auto !important;
  padding: 20px;
}

.left-part {
  display: block;
  float: left;
}

.right-part {
  display: block;
  float: right;
}

.frontpage-headline {
  color: #fff;
  font-size: 9em;
  white-space: nowrap;
  font-weight: 700;
  line-height: 1.8em;
  margin-top: 0.2em;
}

.latest-record {
  padding: 2em 3em;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin-top: -40px;
  margin-bottom: 5em;
  max-width: 350px;
  backdrop-filter: blur(20px);
  background-color: #25292c;
}

.latest-record a {
  text-decoration: none;
}

.latest-record img {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin-bottom: 2em;
  width: 400px;
}

.latest-record .cover-image {
  max-width: 350px;
  width: 100%;
}

.recently-added {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2em;
  font-weight: 800;
  letter-spacing: 5px;
}

.band-title {
  font-size: 1.8em;
  font-weight: 700 !important;
  letter-spacing: 1px;
  margin-bottom: 0.3em;
  text-transform: uppercase;
  text-align: center;
  overflow-wrap: break-word;
}

.band-album {
  font-size: 1.2em;
  margin-bottom: 0.6em;
}

.cover-image {
  display: block;
  max-width: 280px;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: transform 300ms;
  transition: ease-in-out 300ms;
}

.cover-image:hover {
  transform: scale(1.03);
  box-shadow: rgba(229, 46, 46, 0.25) 0px 50px 100px -20px, rgba(229, 46, 46, 0.4) 0px 30px 60px -30px;
}

.cover-big {
  max-width: 500px !important;
  width: 100%;
}

.record {
  display: inline-block;
  width: 280px;
  margin: 0 20px 20px 0;
}

.record a {
  text-decoration: none;
}

/* Create new record form */
.create {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.create label {
  text-align: left;
  display: block;
  margin-bottom: 5px;
}

.create h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.create input, .create textarea, .create select {
  width: 100%;
  padding: 13px;
  margin: 0 0 15px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
  border-radius: 4px;
  background-color: #fff;
}


/* ------ Footer ------ */
.site-footer {
  display: block;
  clear: both;
  padding: 50px 0px;
  text-align: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #00000020;
}

.footer-logo {
  font-weight: 800;
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 2.5px;
}

.site-info {
  font-size: 0.8em;
}


/* ------ Responsiv design ------ */

@media screen and (max-width: 965px) {
  .right-part {
    display: block;
    clear: both;
    width: 100%;
    margin-top: 110px;
    margin-bottom: 50px;
  }

  .latest-record {
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .menu {
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }

  .list {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .navbar {
    background-color: #1C1D20;
    padding: 30px 30px;
    color: #fff;
  }

  .navbar a {
    color: #fff;
  }

  .navbar a:hover, .navbar .link-active {
    color: #fff;
    border-bottom: 1px solid #fff;
  }

  .navbar label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  .navbar i {
    font-style: normal;
    color: #fff;
  }

  .navbar .menu {
    font-size: 2em;
    transition: all 0.3s ease 0s;
  }

  #toggler:checked ~ .menu {
    max-height: 100%;
    margin-top: 50px;
    margin-bottom: 104px;
  }

  .extreme {
    letter-spacing: -1px;
    margin: 0.8em;
  }

  .bg {
    margin-top: 0px !important;
    z-index: -10;
  }

  .latest-record {
    max-width: 350px;
    display: block;
    clear: both;
    margin-top: -40px;
  }

  .left, .right {
    display: block;
    clear: both;
    max-width: 100%;
  }

}


